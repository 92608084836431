<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <section class="invoice-preview-wrapper">
          <div v-if="order" class="row invoice-preview">
            <div class="col-12 col-xl-9 col-md-8">
              <div class="card invoice-preview-card">
                <div class="card-body invoice-padding pb-0">
                  <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                    <div>
                      <div class="logo-wrapper">
                        <logo />
                        <h3 class="text-primary invoice-logo">Brandstock</h3>
                      </div>
                      <p class="card-text mb-25">Срибнокильская 3Б, г. Киев</p>
                      <p class="card-text mb-25">Украина, 02095</p>
                      <p class="card-text mb-0">info@brandstock.online</p>
                    </div>
                    <div class="mt-md-0 mt-2">
                      <h4 class="invoice-title">
                        Заказ <span class="invoice-number">#{{ $route.params.id }}</span>
                      </h4>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">Дата заказа:</p>
                        <p class="invoice-date">{{ $formatters.formattedDate(order.created_at) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="invoice-spacing">
                <div v-if="order.user" class="card-body invoice-padding pt-0">
                  <div class="row invoice-spacing">
                    <div class="col-12 col-xl-6 p-0">
                      <h6 class="mb-2">Покупатель:</h6>
                      <h6 class="mb-25">{{ order.user.name }} {{ order.user.surname }}</h6>
                      <p class="card-text mb-25">{{ order.user.company_name }}</p>
                      <p class="card-text mb-25">{{ order.user.phone }}</p>
                      <p class="card-text mb-0">{{ order.user.email }}</p>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table b-table">
                    <thead>
                    <!---->
                    <tr>
                      <th>Товар</th>
                      <th>Фото</th>
                      <th>Размер</th>
                      <th>Состояние</th>
                      <th>К-во</th>
                      <th>Вес (кг)</th>
                      <th>Сумма</th>
                    </tr>
                    </thead>
                    <tbody>
                      <template v-for="item in order.items">
                        <tr :key="item.id">
                          <td>
                            <b-link class="text-body" :to="{name: 'product-details', params: { id: item.product_specification.product.id}}">
                              {{ item.product_specification.product.name }} {{ item.product_specification.product.brand_name }}
                            </b-link><br/>
                            <small>Артикул: {{ item.product_specification.product.sku }}</small> <br/>
                            <small>Мерки: {{ item.product_specification.full_measurements }}</small> <br/>

                          </td>
                          <td class="p-0 text-center">
                            <img
                                v-if="item.product_specification.product.small_main_image"
                                :src="`/img/product/${item.product_specification.product.small_main_image}`"
                                class="product-img" alt=""
                            >
                          </td>
                          <td class="text-uppercase">{{ item.product_specification.size_name }}</td>
                          <td class="text-capitalize">{{ item.product_specification.condition_name }}</td>
                          <td>{{ item.qty }}</td>
                          <td>{{ item.product_specification.weight/1000 }}</td>
                          <td>{{ item.cost }} <span class="currency">{{ item.currency.name }}</span></td>
                        </tr>
                        <tr :key="item.id + '2'" class="bg-light">
                          <td colspan="7">
                            <small>
                              <span class="font-weight-bold">Состав: </span>
                              <span>{{ item.product_specification.product.full_materials }}</span>
                            </small>
                          </td>
                        </tr>
                      </template>
<!--                    <OrderItem v-for="item in order.items" :key="item.id" :item="item"/>-->
                    </tbody>
                  </table>
                </div>
                <div class="card-body invoice-padding pb-0">
                  <div class="row">
                    <div class="col-12 col-md-6 mt-md-0 mt-3 order-2 order-md-1">
                      <div class="card-text mb-0">
                        <span class="font-weight-bold">Менеджер:</span>
                        <span class="ml-75">Анастасия</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 order-1 order-md-2 mt-md-6 d-flex justify-content-end">
                      <div class="invoice-total-wrapper">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Кол-во:</p>
                          <p class="invoice-total-amount">{{ order.total_qty }}</p>
                        </div>
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Вес (кг):</p>
                          <p class="invoice-total-amount">{{ order.total_weight/1000 }}</p>
                        </div>
                        <hr class="my-50">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Сумма:</p>
                          <p class="invoice-total-amount">
                            {{ order.total_amount }}
                            <span class="currency" v-if="order.items[0]">
                              {{ order.items[0].currency.name }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="invoice-spacing">
                <div class="card-body invoice-padding pt-0">
                  <span class="font-weight-bold">Пометки: </span>
                  <span>Спасибо за заказ. Приятно работать с вами. Надеемся на долговременное сотрудничество!</span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3 invoice-actions">
              <div class="card">
                <div class="card-body">
                  <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      class="mb-75"
                      block
                      @click="printInvoice"
                  >
                    Распечатать
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

</template>

<script>
import { BButton, BLink } from 'bootstrap-vue';
import Logo from '@core/layouts/components/Logo.vue';
import Ripple from 'vue-ripple-directive';

export default {
  name: 'OrderDetails',
  directives: {
    Ripple,
  },
  data() {
    return {
      order: {
        items: [],
      },
    };
  },
  components: {
 BLink, BButton, Logo,
},
  async mounted() {
    await this.getOrderInfo();
  },
  methods: {
    async getOrderInfo() {
      this.order = (await this.$api.orders.get(this.$route.params.id)).data;
    },
    printInvoice() {
      window.print();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}

.product-img {
  max-height: 80px;
}

span.currency {
  font-size: 10px;
}
</style>
